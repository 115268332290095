// src/components/DevelopmentToolbar/index.js
import React, { useState, useRef, useEffect } from 'react';

export function DevelopmentToolbar() {
    console.log('DevelopmentToolbar - Starting render');
    console.log('NODE_ENV:', process.env.NODE_ENV);

    // Always call hooks at the top level
    const [toolbarPosition, setToolbarPosition] = useState({
        x: typeof window !== 'undefined' ? window.innerWidth / 2 : 500,
        y: 10
    });
    const [isDragging, setIsDragging] = useState(false);
    const [mouseStart, setMouseStart] = useState({ x: 0, y: 0 });
    const [initialPos, setInitialPos] = useState({ x: 0, y: 0 });
    const toolbarRef = useRef(null);

    useEffect(() => {
        function handleMouseMove(e) {
            if (!isDragging) return;
            const deltaX = e.clientX - mouseStart.x;
            const deltaY = e.clientY - mouseStart.y;

            setToolbarPosition({
                x: initialPos.x + deltaX,
                y: initialPos.y + deltaY
            });
        }

        function handleMouseUp() {
            setIsDragging(false);
        }

        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, mouseStart, initialPos]);

    const startDragging = (e) => {
        setIsDragging(true);
        setMouseStart({ x: e.clientX, y: e.clientY });
        setInitialPos({ x: toolbarPosition.x, y: toolbarPosition.y });
    };

    // Check environment after hooks are defined
    if (process.env.NODE_ENV !== 'development') {
        console.log('Not in development mode');
        return null;
    }

    const hrefMap = {
        Admin: 'http://localhost:3000/dashboard',
        Sparkle: 'http://sparkle.store:3000',
        Shop: 'http://shop.sparkle.store:3000'
    };

    return (
        <div
            ref={toolbarRef}
            style={{
                position: 'absolute',
                top: toolbarPosition.y,
                left: toolbarPosition.x,
                transform: 'translate(-50%, 0)',
                zIndex: 9999,
                background: 'rgba(0,0,0,0.8)',
                padding: '8px',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
                borderRadius: '8px',
                fontFamily: 'sans-serif',
                userSelect: 'none',
                cursor: isDragging ? 'grabbing' : 'auto'
            }}
        >
            {/* Drag Handle */}
            <div
                onMouseDown={startDragging}
                style={{
                    width: '12px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '8px',
                    cursor: 'grab'
                }}
                title="Drag me"
            >
                {/* A simple visual cue for a handle (3 small vertical dots) */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                }}>
                    <span style={{ width: '2px', height: '2px', background: '#ccc', margin: '2px 0' }}></span>
                    <span style={{ width: '2px', height: '2px', background: '#ccc', margin: '2px 0' }}></span>
                    <span style={{ width: '2px', height: '2px', background: '#ccc', margin: '2px 0' }}></span>
                </div>
            </div>

            {/* Buttons */}
            {['Admin', 'Sparkle', 'Shop'].map((label) => (
                <button
                    key={label}
                    style={{
                        border: 'none',
                        borderRadius: '4px',
                        padding: '6px 12px',
                        cursor: 'pointer',
                        backgroundColor: '#444',
                        color: '#fff',
                        transition: 'background-color 0.2s ease',
                    }}
                    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#333'}
                    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#444'}
                    onClick={() => window.location.href = hrefMap[label]}
                >
                    {label}
                </button>
            ))}
        </div>
    );
}
