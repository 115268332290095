// index.js
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import { HelmetProvider } from 'react-helmet-async';
import { GlobalStyles } from "./GlobalStyles";
import { StoreContext } from "./hooks/storeContext";
import store from "./_store/index";
import { ROUTES } from "./constants/routes";

// Wrap your routes with App as the root element
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: ROUTES
  }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <StoreContext.Provider value={store}>
        <ConfigProvider>
            <HelmetProvider>
                <GlobalStyles />
                <RouterProvider router={router} />
            </HelmetProvider>
        </ConfigProvider>
    </StoreContext.Provider>,
);