// src/App.js
import { Outlet } from "react-router-dom";
import React, { useEffect, Suspense } from 'react';
import { DevelopmentToolbar } from './components/DevelopmentToolbar';
import loadable from '@loadable/component';

// Import the lazy-loaded components you want to preload
const Dashboard = loadable(() => import('./pages/Dashboard'));
const MyStore = loadable(() => import('./pages/MyStore'));
const Products = loadable(() => import('./pages/MyStore/components/Products'));
const Store = loadable(() => import('./pages/MyStore/components/Shop'));

function App() {
    useEffect(() => {
        // Preload important components
        const preloadComponents = async () => {
            try {
                await Promise.all([
                    Dashboard.load(),
                    MyStore.load(),
                    Products.load(),
                    Store.load()
                ]);
                console.log('Components preloaded successfully');
            } catch (error) {
                console.error('Error preloading components:', error);
            }
        };

        preloadComponents();
    }, []);

    return (
        <>
            <DevelopmentToolbar />
            <Suspense fallback={<div>Loading...</div>}>
                <Outlet />
            </Suspense>
        </>
    );
}

export default App;
