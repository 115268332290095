const API_URL = process.env.REACT_APP_BACKEND_URL;

const fullUrl = (path) => {
    return `${API_URL}${path}`;
};

export const api = {
    auth: {
        signUp: fullUrl("/auth/signup"),
        check: fullUrl("/auth/check"),
        signIn: fullUrl("/auth/signin"),
        whoami: fullUrl("/auth/whoami"),
        goal: fullUrl("/auth/onboarding/goal"),
        bio: fullUrl("/auth/onboarding/bio"),
        bioText: fullUrl("/auth/onboarding/bioText"),
        niche: fullUrl("/auth/onboarding/niche"),
        socialLinks: fullUrl("/auth/onboarding/social-links"),
        template: fullUrl("/auth/onboarding/template"),
        verifyEmail: (token) => fullUrl(`/auth/verify-email?token=${token}`),
        resendVerification: fullUrl("/auth/resend-verification"),
        forgotPassword: fullUrl("/auth/forgot-password"),
        resetPassword: fullUrl("/auth/reset-password"),
    },
    shopAuth: {
        signIn: fullUrl("/shopAuth/signin"),
        whoami: fullUrl("/shopAuth/whoami"),
    },
    bookings: {
        create: fullUrl("/bookings/create-booking"),
        fetch: fullUrl("/bookings/bookings"),
        update: (id) => fullUrl(`/bookings/bookings/${id}`),
        updateBookingProduct: (id) => fullUrl(`/bookings/booking-products/${id}`),
        delete: (id) => fullUrl(`/bookings/bookings/${id}`),
        getById: (id) => fullUrl(`/bookings/bookings/${id}`),
        getByProductId: (id) => fullUrl(`/bookings/bookings-by-productId/${id}`),
        deleteBooking: fullUrl("/bookings/delete-booking-product/:id"),
    },
    twilio: {
        getVideoToken: fullUrl('/twilio/token'),
        leaveRoom: fullUrl('/twilio/room/leave'),
        checkRoom: fullUrl('/twilio/room'),
        createRoomRecord: fullUrl('/twilio/create-room'),
        requestJoin: fullUrl('/twilio/request-join'),
        acceptParticipant: fullUrl('/twilio/accept'),
        getParticipantToken: fullUrl('/twilio/get-token'),
        getRoomDetails: (roomName) => fullUrl(`/twilio/room/details/${roomName}`),

        getRoomByBooking: (bookingId) => fullUrl(`/twilio/room/booking/${bookingId}`),
        checkRoomStatus: (roomName) => fullUrl(`/twilio/room/${roomName}/status`),
        roomParticipants: (roomName) => fullUrl(`/twilio/room/${roomName}/participants`),
    },
    store: {
        userStoreData: (username) => fullUrl(`/store/${username}`),
        updateViewCount: (username) => fullUrl(`/store/${username}/view`),
        updateShopViewCount: (storeName) => fullUrl(`/store/${storeName}/shop-view`),
        updateProductClick: (storeName, productId, productType) => fullUrl(`/store/${storeName}/product/${productId}/click/${productType}`),
        checkout: (username, productSlug) => fullUrl(`/store/${username}/checkout/${productSlug}`),
        addEmailUser: (storeName) => fullUrl(`/store/${storeName}/emails`),
        createCustomerPaymentIntent: (storeName) => fullUrl(`/store/${storeName}/create-payment-intent`),
        onPaymentSuccess: (storeName) => fullUrl(`/store/${storeName}/payment-success`),
        processFreeBooking: (storeName) => fullUrl(`/store/${storeName}/process-free-booking`),
        getDownloadLink: (storeName) => fullUrl(`/store/${storeName}/download`),
        claimAccount: (storeName) => fullUrl(`/store/${storeName}/claim-account`),
        processFreeDownload: (storeName) => fullUrl(`/store/${storeName}/process-free-download`),
        fetchCommerceProducts: (storeName) => fullUrl(`/store/${storeName}/get-commerce-products`),

        //Stripe Sessions
        createDigitalCheckoutSession: fullUrl('/store/create-digital-checkout-session'),
        createBookingSession: fullUrl('/bookings/create-booking-session'),
        createTipSession: fullUrl('/bookings/create-tip-session'),

        //Digital product download via email link
        downloadProductByToken: (token) => fullUrl(`/store/download/${token}`),
        verifyDownloadToken: (token) => fullUrl(`/store/verify-download/${token}`),

        //On Success of Checkout for Digital Products
        verifyDigitalPayment: (sessionId) => fullUrl(`/store/verify-digital-payment/${sessionId}`),
        downloadDigitalProduct: (sessionId) => fullUrl(`/store/download-digital-product/${sessionId}`),

        //On Success of Checkout for Booking Products
        verifyBookingPayment: (sessionId) => fullUrl(`/store/verify-booking-payment/${sessionId}`),
        //On Success of Checkout for Tip Products
        verifyTipPayment: (sessionId) => fullUrl(`/bookings/verify-tip-payment/${sessionId}`),
    },
    billing: {
        setup: () => fullUrl('/billing/intent/setup'),
        confirm: () => fullUrl('/billing/intent/confirm/no-redirect'),
        createSubscription: () => fullUrl('/billing/subscription/create'),
        createCheckoutSession: fullUrl('/billing/create-checkout-session'),
        getBillingPlan: () => fullUrl('/billing/plan/get'),
        addOrUpdatePaymentMethod: () => fullUrl('/billing/card/add-or-update'),
        createAccountLink: () => fullUrl('/billing/account/create-account-link'),
        deleteAccountLink: () => fullUrl('/billing/account/delete-account-link'),
        updateAccount: () => fullUrl('/billing/account/update-account-link'),
        cancelSubscription: () => fullUrl('/billing/subscription/cancel'),
        detachCard: fullUrl('/billing/card/detach'),
        requestCashout: fullUrl('/metrics/request-cashout'),
        checkVerification: () => fullUrl('/billing/account/check-verification'),
    },
    customers: {
        fetchCustomers: fullUrl("/customers/get-customers"),
        fetchEmails: fullUrl("/customers/get-emails"),
        fetchCustomerById: (id) => fullUrl(`/customers/get-customer/${id}`),
        fetchEmailById: (id) => fullUrl(`/customers/get-email/${id}`),
    },
    userProfile: {
        fetch: fullUrl("/auth/profile/user-profile"),
        update: fullUrl("/auth/profile/user-profile/:id"),
        updateDetails: fullUrl("/auth/profile/user-profile-details/:id"),
        updatePassword: fullUrl("/auth/profile/update-password"),
        updateAnalytics: fullUrl("/auth/profile/update-analytics"),
        updateAddress: fullUrl("/auth/profile/update-address"),
        updateOtherSettings: fullUrl("/auth/profile/update-other-settings"),
        fetchTheme: fullUrl("/auth/profile/theme"),
        updateDashboardVisit: fullUrl("/auth/profile/user-profile/update-dashboard-visit"),
        updateVerificationStatus: fullUrl("/auth/profile/update-verification"),
    },
    theme: {
        saveCardStyle: fullUrl("/auth/profile/save-card-style"),
        saveButtonStyle: fullUrl("/auth/profile/save-button-style"),
        saveBackground: fullUrl("/auth/profile/save-background"),
        uploadBackgroundImage: fullUrl("/auth/profile/upload-background-image"),
        uploadBackgroundVideo: fullUrl("/auth/profile/upload-background-video"),
        saveSelectedTheme: fullUrl("/auth/profile/save-selected-theme"),
        fetchSparkleTheme: fullUrl("/auth/profile/get-sparkle-theme"),
        saveImageStyle: fullUrl("/auth/profile/save-image-style"),
        saveFontStyle: fullUrl("/auth/profile/save-font-style"),
    },
    products: {
        create: fullUrl("/auth/store/create-product"),
        fetch: fullUrl("/auth/store/get-products"),
        fetchEdit: fullUrl("/auth/store/edit/:type/:id"),
        delete: fullUrl("/auth/store/delete-product/:id"),
        update: fullUrl("/auth/store/update-product/:type/:id"),
        updateCustom: fullUrl("/auth/store/update-custom-product/:id"),
        updateVideo: fullUrl("/auth/store/update-video-product/:id"),
        updateEmail: fullUrl("/auth/store/update-email-product/:id"),
        updateUrl: fullUrl("/auth/store/update-url-product/:id"),
        unpublish: fullUrl("/auth/store/unpublish-product/:id"),
        publish: fullUrl("/auth/store/publish-product/:id"),
        createCommerceProduct: fullUrl("/auth/store/create-commerce-product"),
        fetchCommerceProduct: fullUrl("/auth/store/commerce-product/:id"),
        updateCommerceProduct: fullUrl("/auth/store/update-commerce-product/:id"),
        deleteCommerceProduct: fullUrl("/auth/store/delete-commerce-product/:id"),

        //Ecommerce
        scrapeProduct: fullUrl("/auth/store/scrape-product"),
        updatePublishStatus: (id) => fullUrl(`/auth/store/update-product-publish-status/${id}`),
        fetchShopSettingsandMetrics: fullUrl("/auth/store/get-shop-settings"),
        updateShopSettings: fullUrl("/auth/store/update-shop-settings"),

        //spotify
        spotifyCreate: fullUrl("/auth/store/spotify/create"),
        spotifyUpdate: (id) => fullUrl(`/auth/store/spotify/update/${id}`),
        spotifyGet: (id) => fullUrl(`/auth/store/spotify/${id}`),
        spotifyMetadata: fullUrl("/auth/store/spotify/metadata"),

        //apple
        appleCreate: fullUrl("/auth/store/apple/create"),
        appleUpdate: (id) => fullUrl(`/auth/store/apple/update/${id}`),
        appleGet: (id) => fullUrl(`/auth/store/apple/${id}`),
        appleMetadata: fullUrl("/auth/store/apple/metadata"),

        //tip
        updateTipProduct: (id) => fullUrl(`/auth/store/update-tip-product/${id}`),
        
    },
    digitalProducts: {
        uploadImage: fullUrl('/auth/store/digital-products/input-form/upload-image'),
        uploadVideo: fullUrl('/auth/store/digital-products/input-form/upload-video')
    },
    headlineSections: {
        create: fullUrl("/auth/store/create-headline"),
        fetch: fullUrl("/auth/store/get-headlines"),
        delete: fullUrl("/auth/store/delete-headline/:id"),
        update: fullUrl("/auth/store/update-headline/:id"),
    },
    block: {
        update: fullUrl("/auth/store/update-block-order")
    },
    orders: {
        fetchUnfulfilled: fullUrl("/metrics/unfulfilled-orders"),
        fetchFulfilled: fullUrl("/metrics/fulfilled-orders"),
        getFulfillmentDetails: (orderId) => fullUrl(`/metrics/orders/${orderId}/fulfillment-details`),
        updateFulfillment: (orderId) => fullUrl(`/metrics/orders/${orderId}/fulfillment`),
        getOrderDetails: (orderId) => fullUrl(`/metrics/orders/${orderId}`),
        uploadFulfillment: (orderId) => fullUrl(`/metrics/orders/${orderId}/upload`),
        getFulfillment: (token) => fullUrl(`/metrics/fulfillment/token/${token}`),
        downloadFulfillmentFile: (token, fileKey) => fullUrl(`/metrics/fulfillment/token/${token}/download/${fileKey}`),
    },
    metrics: {
        fetch: fullUrl("/metrics/get-metrics"),
        getShopMetrics: fullUrl("/metrics/get-shop-metrics"),
        fetchBalances: fullUrl('/metrics/balances'),
    },
    transactions: {
        fetch: fullUrl("/metrics/get-transactions"),
        fetchById: (id) => fullUrl(`/metrics/get-transaction/${id}`),
        refund: (id) => fullUrl(`/billing/customer/refund-transaction/${id}`),
        fetchCashoutHistory: fullUrl("/metrics/get-cashout-history"),

        //Hook this up
        checkStatus: (id) => `/api/transactions/${id}/status`,
        fetchByCashout: (cashoutId) => `/api/transactions/cashout/${cashoutId}`,
        fetchAvailable: '/api/transactions/available',
    },
    referrals: {
        fetch: fullUrl("/auth/profile/get-referral-data"),
    },
    services: {
        checkUserNameAvailablity: fullUrl("/auth/check-username"),
    },
    tools: {
        generate: fullUrl("/auth/generate-business-name"),
        domainCheck: fullUrl("/auth/generate-domain-name"),
        generateFreeImage: fullUrl("/auth/generate-image-generator"),
    },
    customize: {
        fetchImage: fullUrl("/auth/get-ai-image"),
    },
    shop: {
        fetchCategories: fullUrl("/shop/categories"),
        createCategory: fullUrl("/shop/categories"),
        getCategoryById: (id) => fullUrl(`/shop/categories/${id}`),
        updateCategory: (id) => fullUrl(`/shop/categories/${id}`),
        updateCategoryStatus: (id) => fullUrl(`/shop/categories/${id}/status`),
        updateCategorySortOrder: (id) => fullUrl(`/shop/categories/${id}/sort-order`),
        getCollectionsForCategory: (id) => fullUrl(`/shop/categories/${id}/collections`),
        deleteCategory: (id) => fullUrl(`/shop/categories/${id}`),

        fetchCollections: fullUrl("/shop/collections"),
        createCollection: fullUrl("/shop/collections"),
        getCollectionById: (id) => fullUrl(`/shop/collections/${id}`),
        updateCollection: (id) => fullUrl(`/shop/collections/${id}`),
        deleteCollection: (id) => fullUrl(`/shop/collections/${id}`),
        updateCollectionStatus: (id) => fullUrl(`/shop/collections/${id}/status`),

        fetchProducts: fullUrl("/shop/products"),
        createProduct: fullUrl("/shop/products"),
        fetchProduct: (id) => fullUrl(`/shop/products/${id}`),
        updateProduct: (id) => fullUrl(`/shop/products/${id}`),
        deleteEcommerceProduct: (id) => fullUrl(`/shop/products/delete-ecommerce/${id}`),
        deleteProductImage: (productId) => fullUrl(`/shop/products/${productId}/images`),
        duplicateProduct: (id) => fullUrl(`/shop/products/${id}/duplicate`),

        uploadImage: fullUrl("/shop/upload-image"),

        fetchOrders: fullUrl("/shop/orders"),
        updateOrderStatus: (id) => fullUrl(`/shop/orders/${id}/status`),
        fetchOrderById: (id) => fullUrl(`/shop/orders/${id}`),
        refundOrder: (id) => fullUrl(`/shop/orders/${id}/refund`),
        fetchShopMetrics: fullUrl("/shop/metrics"),
    },
    ecommerceShop: {
        fetchCollections: (category) => fullUrl(`/shop/collections/shop/${category}`),
        fetchCategories: fullUrl("/shop/category/fetch-categories"),
        fetchProductsForCollection: (category, collection) => fullUrl(`/shop/collections/shop/${category}/${collection}`),
        fetchProduct: (category, collection, productSlug) => fullUrl(`/shop/${category}/${collection}/${productSlug}`),
        addProductToCreatorStore: fullUrl('/store/creator/add-product'),
        deleteEcommerceProduct: (id) => fullUrl(`/store/delete-ecommerce/${id}`),
        fetchProductByCreator: (storeName, productSlug) => fullUrl(`/store/${storeName}/${productSlug}`),
        createCheckoutSession: fullUrl('/store/create-checkout-session'),
        fetchOrderDetails: (sessionId) => fullUrl(`/store/checkout/success?session_id=${sessionId}`),
        closeDialogBox: fullUrl('/store/close-dialog-box'),
        fetchDialogSettings: fullUrl('/store/dialog-settings'),
    },
};

export default api;